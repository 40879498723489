import type { Action, Subject } from '@/abilities';
import type { cardTemplateIds, pageTemplateIds } from '@/renderTemplates';
import type { Component } from 'vue';
import type { LocationQuery, RouteMeta } from 'vue-router';
import type { RoleName, RoleType } from './auth';
import { DEFAULT_LOCALE, type supported_locale } from '@/config';
import { translateStringOrLocaleWithDefault } from '@/i18n';
import type { VoteDirection } from '@/enums/VoteDirection';
import type {
  SuggestionInternalStatus,
  VssStatus,
} from '@/utilities/getSuggestionStatus';
import type { TranslatedBlock } from './blocks';
import type {
  DoclistVueAttributes,
  SearchbarVueAttributes,
} from '@/utilities/markdown/GnistMarkdownExtension';
import type { getBlobUrl } from './blob';
import type { FieldSetKey, TextSetKey } from './field_labels';

export type KeysOfUnion<T> = T extends T ? keyof T : never; // Source: https://stackoverflow.com/a/49402091

/** Source: https://stackoverflow.com/a/71353081
 * This is a tail-recursive template literal type which uses a type parameter A to accumulate the result.
 * If T is of the form L+S+R for some (possibly empty) "left part" L and some (possibly empty) "right part" R,
 * then you want to use L as-is, replace S with D, and then continue doing Replacements on R.
 * Otherwise, T doesn't contain S and you just want to use T as-is. */
export type Replace<
  T extends string,
  S extends string,
  D extends string,
  A extends string = '',
> = T extends `${infer L}${S}${infer R}`
  ? Replace<R, S, D, `${A}${L}${D}`>
  : `${A}${T}`;

/** Returns the type of TInObject without any properties of type TKeyType */
export type ExcludeKeysWithValsOfType<TInObject, TKeyType> = {
  [TKey in keyof TInObject as NonNullable<TInObject[TKey]> extends TKeyType
    ? never
    : TKey]: TInObject[TKey];
};
/** Returns the type of TInObject, but limitied to properties of type TKeyType */
export type OnlyKeysWithValsOfType<TInObject, TKeyType> = {
  [TKey in keyof TInObject as NonNullable<TInObject[TKey]> extends TKeyType
    ? TKey
    : never]: TInObject[TKey];
};
/** Returns the type of TInObject, but limited to properties that extends the type TKeyType, but does not extend TNotKeyType.
 * Example: if you have a property that is either type X or string (<X|string>), KeysWithValsOfType will also return pure string properties. Using this, you can exclude those properties that are only strings (because <X|string> does not extend string).
 */
export type OnlyKeysWithValsOfTypeButNotType<TInObject, TKeyType, TNotKeyType> =
  {
    [TKey in keyof TInObject as NonNullable<TInObject[TKey]> extends TNotKeyType
      ? never
      : NonNullable<TInObject[TKey]> extends TKeyType
        ? TKey
        : never]: TInObject[TKey];
  };
/** The opposite of OnlyKeysWithValsOfTypeButNotType */
export type ExcludeKeysWithValsOfTypeButNotType<
  TInObject,
  TKeyType,
  TNotKeyType,
> = {
  [TKey in keyof TInObject as NonNullable<TInObject[TKey]> extends TNotKeyType
    ? TKey
    : TInObject[TKey] extends TKeyType
      ? never
      : TKey]: TInObject[TKey];
};
/** Returns the type of TInObject, but limited to properties that are arrays of type TKeyType */
export type OnlyArrayKeysWithItemsOfType<TInObject, TKeyType> = {
  [TKey in keyof TInObject as NonNullable<TInObject[TKey]> extends unknown[]
    ? NonNullable<TInObject[TKey]>[number] extends TKeyType
      ? TKey
      : never
    : never]: TInObject[TKey];
};
export type ArrayKeysExceptWithItemsOfType<TInObject, TKeyType> = {
  [TKey in keyof TInObject as NonNullable<TInObject[TKey]> extends unknown[]
    ? NonNullable<TInObject[TKey]>[number] extends TKeyType
      ? never
      : TKey
    : never]: TInObject[TKey];
};
/** Returns the type of TInObject, but limitied to properties that are either of type TKeyType or arrays of type TKeyType */
export type OnlyKeysOfTypeOrArrayOfType<TInObject, TKeyType> =
  OnlyKeysWithValsOfTypeButNotType<TInObject, TKeyType, unknown[]> &
    OnlyArrayKeysWithItemsOfType<TInObject, TKeyType>;

export type ExcludeKeysOfTypeOrArrayOfType<TInObject, TNotKeyType> =
  ExcludeKeysWithValsOfType<TInObject, TNotKeyType | unknown[]> &
    ArrayKeysExceptWithItemsOfType<TInObject, TNotKeyType>;

export type OnlyKeysOfTypeOrArrayOfTypeButNotType<
  TInObject,
  TKeyType,
  TNotKeyType,
> = OnlyKeysWithValsOfTypeButNotType<
  TInObject,
  TKeyType,
  unknown[] | TNotKeyType
> &
  ExcludeKeysWithValsOfType<
    OnlyArrayKeysWithItemsOfType<TInObject, TKeyType>,
    TNotKeyType[]
  >;
/** **Example:** If the generics are `<LayoutItem, object, BlockValue | TailwindSplitOption>`:

 * When we talk about matching properties below, it means properties that are objects or array of objects, but not `BlockValue|TailwindSplitOption` (or arrays of those).

 * Pick all properties in `LayoutItem` that matches => should as of writing pick: `searchbar, taglist, doclist`

 * Chage all properties in those subproperties that are matches to `unknown`
   => e.g., in doclist the property `tags:Tag[]` is changed to `tags:unknown[]`
*/
export type SelectPropsOfTypeAndChangeSubPropsOfTypeButNotTypeIntoUnknown<
  TInObject,
  TKeyType,
  TNotKeyType,
> = {
  [TKey in keyof OnlyKeysOfTypeOrArrayOfTypeButNotType<
    TInObject,
    TKeyType,
    TNotKeyType
  >]: ExcludeKeysOfTypeOrArrayOfType<TInObject[TKey], TKeyType> & {
    [TSubKey in keyof OnlyKeysOfTypeOrArrayOfType<
      NonNullable<TInObject[TKey]>,
      TKeyType
    >]: NonNullable<NonNullable<TInObject[TKey]>[TSubKey]> extends unknown[]
      ? unknown[]
      : unknown;
  };
};

/** Get keys of T that are not symbols */
type NoSymbolKey<T> = keyof T extends string | number ? keyof T : never;
/** Matches record and can infer the list of keys, but does not match strings (Record<infer K,V> matches strings). Also handles optional keys and nullable types so we always get the keys. */
type HasKeys<T, K extends NoSymbolKey<T> = NoSymbolKey<T>> =
  Required<NonNullable<T>> extends object & Record<K, unknown> ? T : never;
/** Returns all keys and subkeys recursively, with a dot between */
export type KeyOfRecursive<
  T,
  IncludeParentKeys extends boolean = false,
> = string &
  (T extends Array<unknown>
    ? never
    : T extends HasKeys<T, infer K>
      ?
          | (IncludeParentKeys extends true ? keyof T : never)
          | keyof {
              [key in K as Required<T>[key] extends Array<unknown>
                ? key
                : T[key] extends HasKeys<T[key]>
                  ? `${key}.${KeyOfRecursive<T[key], IncludeParentKeys>}`
                  : key]: unknown;
            }
      : never);
/** Can be used to get the type of a property matching KeyOfRecursive<T> */
export type RecurChildType<
  T,
  K extends KeyOfRecursive<T, true>,
> = K extends keyof T
  ? T[K]
  : K extends `${infer P extends keyof T & string}.${infer C}`
    ? C extends KeyOfRecursive<T[P], true>
      ? RecurChildType<T[P], C>
      : never
    : never;
/** Get only matches from KeyOfRecursive<T> that has child keys. */
type KeysWithChildren<T, CB extends string | number = ''> =
  T extends HasKeys<T, infer KParent>
    ? KParent extends KeyOfRecursive<T, false>
      ? CB extends ''
        ? never
        : CB
      : T[KParent] extends HasKeys<T[KParent]>
        ? `${CB extends '' ? '' : `${CB}.`}${KeysWithChildren<T[KParent], KParent> & string}`
        : never
    : never;
/** Gets all values from KeyOfRecursive<T> that starts with the given prefix (i.e. keys below a given parent). The given prefix must be a key that has sub keys. */
export type KeyOfRecursiveBelow<T, K extends KeysWithChildren<T>> = Extract<
  KeyOfRecursive<T, false>,
  `${K}${string}`
>;
/** Helper to read properties passed as KeyOfRecursive<T> */
export function getPropertyRecursive<T, K extends KeyOfRecursive<T, true>>(
  obj: T,
  property: K,
): RecurChildType<T, K> {
  if (obj === null || obj === undefined) return obj as RecurChildType<T, K>;
  const parts = property.split('.');
  const firstKey = parts.splice(0, 1)[0] as keyof T;
  if (!firstKey) {
    throw new Error('Illegal property');
  }
  if (parts.length === 0) {
    return obj[firstKey] as RecurChildType<T, K>;
  }
  return getPropertyRecursive(
    obj[firstKey] as T[keyof T],
    parts.join('.') as KeyOfRecursive<T[keyof T], true>,
  ) as RecurChildType<T, K>;
}
export function removeParentKey<T>(
  property: KeyOfRecursive<T, false>,
  parentProperty: KeysWithChildren<T>,
) {
  return property.replace(`${parentProperty}.`, '') as Replace<
    Extract<typeof property, `${typeof parentProperty}${string}`>,
    `${typeof parentProperty}.`,
    ''
  >;
}

/** Returns the item type for an Array type (and never if the type is not an array) */
export type ArrayItem<T> = T extends Array<infer U> ? U : never;

/** Returns the item type for an Array type, and the same type if not an array type */
export type SingleItem<T> = T extends Array<infer U> ? U : T;

/** Only those types of a composite type that are NOT arrays */
export type NotArray<T> = T extends Array<unknown> ? never : T;

export type OnlyArray<T> = T extends Array<unknown> ? T : never;

/** Changes keys K to optional */
export type PartialSome<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;
/** Changes keys K to required */
export type RequiredSome<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;
/** Changes all properties in T given by K to P */
export type ChangeProps<T, K extends keyof T, P> = Omit<T, K> & {
  [property in K]: P;
};

/** Same as Required<T>, except that any props that are non-optional, but might be null/undefined, are also required to have a value */
export type RequireValue<T> = {
  [key in keyof Required<T>]: NonNullable<T[key]>;
};
/** Can be used to force any properties in `T` that is not in `TKeep` to be undefined.\
 *  This is useful if you want to use `v-bind` to send a subset of props to a child component, and gives you "type safety" to ensure this is updated if new props are added to the parent. */
export function WithUndefined<T extends Partial<TKeep>, TKeep>(
  obj: T & Partial<TKeep>,
  setsUndefined: {
    [key in keyof Omit<RequireValue<T>, keyof TKeep>]: undefined;
  },
  extraProps?: object,
): Partial<TKeep> & typeof extraProps {
  return { ...obj, ...setsUndefined, ...extraProps };
}

export type VueClass =
  | string
  | Record<string, boolean>
  | (string | undefined | Record<string, boolean>)[];

type CustomSchemaData = {
  schema: Schema[];
  template: pageTemplateIds;
};

export type BlockCreateDTO = {
  name: localeValue;
  categoryId: number;
  customSchema?: CustomSchemaData;
  audiences?: RoleName[];
  description: localeValue;
  tags: number[];
  ownerOrganizationId?: Guid;
  documentOwnerIds?: string[];
  content?: BlockData;
};

export type Guid = `${string}-${string}-${string}-${string}-${string}`;
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000' as Guid;

export type Block = {
  name: localeValue;
  category?: Category;
  customSchema?: CustomSchemaData;
  audiences?: RoleName[];
  description: localeValue;
  blockId: Guid;
  tags: Tag[];
  /** This points to the currently _selected_ versionnumber, might not be the current version from the database. */
  currentVersionNumber?: string;
  currentVersionId: number;
  suggestion?: Pick<
    Suggestion,
    'id' | 'title' | 'status' | 'type' | 'workItem'
  >;
  content: BlockData;
  /** Aggregated status for the block, based on logic defined in the backend */
  status: Status;
  hasBacklog: boolean;
  isBacklog: boolean;
  ownerOrg?: OrganizationInfo;
  documentOwners?: OrganizationUser[];
  versions?: Pick<
    Version,
    'versionNumber' | 'status' | 'draftStatus' | 'isBacklog'
  >[];
  lastChanged?: Date;
  lastChangedBy?: OrganizationUser;
};

export enum Status {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived',
  AwaitingApproval = 'AwaitingApproval',
}

export type UpdateBlock = {
  blockId: Guid;
  name: localeValue;
  categoryId: number;
  customSchema?: CustomSchemaData;
  audiences?: RoleName[];
  description: localeValue;
  tags?: number[];
  OwnerOrganizationId?: Guid;
  documentOwnerIds?: string[];
  content?: BlockData;
};

export type Step = {
  order: number;
  title: BlockLocaleValue;
  description: BlockLocaleValue;
  showApimOrderButton: boolean;
};

export type Favorite = {
  favoriteId: number;
  userId: number;
  blockId: Guid;
  blockName?: localeValue;
  notes?: string;
};

export function translateCategory(
  category: MinimalCategory,
): TranslatedCategory {
  return {
    ...category,
    name: translateStringOrLocaleWithDefault(
      category.name,
      'language.unknownValue',
    ).value,
  };
}
export type MinimalCategory = Pick<
  Category,
  'categoryId' | 'name' | 'color' | 'cardTemplateId' | 'createRoles'
>;
export type TranslatedCategory = Omit<MinimalCategory, 'name'> & {
  name: string;
};
export type Category = {
  categoryId: number;
  name: localeValue;
  description?: string | null;
  color?: Colors;
  sortOrder?: number;
  schema?: Schema[];
  cardTemplateId?: cardTemplateIds;
  pageTemplateId?: pageTemplateIds;
  tagGroups?: string[];
  createRoles?: RoleName[];
  publishRoles?: RoleName[];
  editors?: string[];
  homePageId?: Guid;
};

export const colorList = [
  'yellow',
  'green',
  'orange',
  'purple',
  'gray',
  'gray-light-light',
  'sp-blue-light',
  'blue-light-light',
  'white',
  'green-cyan',
] as const;
export type Colors = (typeof colorList)[number];
export type borderColors = `border-gnist-${Colors}`;
export type decorationColors = `decoration-gnist-${Colors}`;
export const fgColorList = ['black', 'sp-blue-dark'] as const;
export type FgColors = (typeof fgColorList)[number];

export type Tag = {
  id: number;
  text: localeValue;
  color?: Colors;
  primaryTagGroupId?: number;
  tagGroups?: TagGroup[];
  homePageId?: Guid;
};
export type TagGroup = {
  id: number;
  label: localeValue;
  homePageId?: Guid;
  color?: Colors;
  tags?: number[];
  categories?: number[];
};

export type SuggestionUserConnection = {
  userVote?: VoteDirection;
  subscribeForNotifications: boolean;
};
export type Suggestion = {
  id?: number;
  tempId?: Guid;
  title: localeValue;
  description: localeValue;
  type: 'Suggestion' | 'Bug';
  suggestedBy?: OrganizationUser;
  votes?: number;
  last_vote?: string | Date;
  modified?: string | Date;
  commentCount?: number;
  status?: SuggestionInternalStatus;
  tags?: Tag[];
  blockVersion?: {
    id: number;
    blockId?: Guid;
    blockName?: localeValue;
    versionNumber?: string;
  };
  workItem?: Partial<Pick<WorkItem, 'id' | 'title' | 'status' | 'url'>>;
  userConnection?: SuggestionUserConnection;
};
type VssWorkItemType = 'Bug' | 'User Story';
export type WorkItem = {
  id?: number;
  title?: string;
  description?: string;
  status?: VssStatus;
  type?: VssWorkItemType;
  modified?: Date | string;
  stateModified?: Date | string;
  url?: string;
};
export type SuggestionPage = {
  name: localeValue;
  suggestionId: number;
};

export interface RenderTemplateDefinition {
  id: string;
  type: 'card' | 'page';
  requiredFields: (Pick<Schema, 'name' | 'type' | 'canUnsetRequired'> & {
    defaultTitle?: string;
  })[];
  component: Component;
}

export type CardTemplateProps = {
  block: TranslatedBlock;
  routeName?: string;
};

export interface CardTemplateDefinition extends RenderTemplateDefinition {
  type: 'card';
  isLink: boolean;
  component: Component<CardTemplateProps>;
}

export type PageTemplateProps = {
  block: Block;
  bgColor: Colors;
  fgColor?: FgColors;
  isPreview?: boolean;
  showDiscussion?: boolean;
};

export interface PageTemplateDefinition extends RenderTemplateDefinition {
  type: 'page';
  bgColor: Colors;
  fgColor?: FgColors;
  hasVersionSelector: boolean;
  component: Component<PageTemplateProps>;
  showDiscussion?: boolean;
}

export type Version = {
  versionId: number;
  blockId: Guid;
  versionNumber: string;
  content?: BlockData;
  status: Status;
  draftStatus: Status;
  publishedDate: string;
  lastChanged: string;
  isBacklog: boolean;
};

export type VersionUpdate = {
  versionNumber?: string;
  content?: BlockData;
  status?: Status;
};

export type User = {
  id: string;
  issuerUserId: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  roles?: RoleName[];
  lastLogin?: Date;
};
export type UserNotificationSettings = {
  sendCommentReplyNotification: boolean;
  sendNewsNotification: boolean;
  sendSuggestionCommentNotification: boolean;
  sendSuggestionChangedNotification: boolean;
  emailLocale?: supported_locale;
};
export type CurrentUser = User & {
  hideNotificationPrompt: boolean;
  memberOrgs?: Guid[];
  requestedOrgs?: Guid[];
} & UserNotificationSettings;

export type Role = {
  name: RoleName;
  id: number;
  types: RoleType[];
};

export type News = {
  newsArticleId?: string;
  title: localeValue;
  content: localeValue;
  imageSource?: string;
  datePublished?: string;
  published?: boolean;
  url?: string;
  urlText?: string;
};

export type Blob = {
  fullPath: string;
  name: string;
  fileName: string;
  contentType: string;
  contentDisposition: string;
};

export type ErrorMessage = {
  message: string;
  autoClose: boolean;
  closeable: boolean;
};

export type ApiMetaData = {
  name: string;
  displayName?: string;
  description: string;
  apiVersion?: string;
};

export type ApiPickerDTO = {
  name: string;
  displayName?: string;
  versions: ApiMetaData[];
};

export type ApiOperation = {
  name: string;
  apiName: string;
  method: string;
  description: string;
  templateUrl: string;
  /** Calculated in Gnist backend. Points to the TypeName of the first representation of the Api "Request" property (which is present if the operation has a request body). */
  schemaOperation: string | null;
  /** Parameters sent as part of the path */
  templateParameters?: PathParameter[];
  responses: ResponseContract[];
  request: RequestContract | null;
};

export type ApiDTO = {
  displayName: string;
  operations: ApiOperation[];
  gatewayUrl: string;
  apiPathUrl: string;
  schema: string | null;
  subscriptionKeyHeader: string;
};

export type BlockPage = {
  name: localeValue;
  blockId: Guid;
};

type SubscriptionState =
  | 'Suspended'
  | 'Active'
  | 'Expired'
  | 'Submitted'
  | 'Rejected'
  | 'Cancelled';

export type ApiMSubscriptionDTO = {
  id: string;
  displayName: string;
  scope?: string;
  state: SubscriptionState;
  primaryKey: string;
  secondaryKey: string;
};
export type ApimProduct = {
  id: string;
  productName: string;
  description: string;
  terms: string;
  apis: ApiMetaData[];
};

export type ApiSchema = {
  required: string[];
  type: string;
  properties: [];
  example: [];
  xApimInline: boolean;
};

type ParameterContractType =
  | 'integer'
  | 'string'
  | (string & NonNullable<unknown>);

export type RequestHeader = {
  name: string;
  parameterContractType: ParameterContractType;
};

export type RequestContract = {
  description: string;
  queryParameters: QueryParameter[];
  representations: RepresentationContract[];
  headers: RequestHeader[];
};

export type ResponseContract = {
  statusCode: number;
  description: string;
  representations: RepresentationContract[];
  headers: {
    name: string;
  }[];
};

export type RepresentationContract = {
  typeName: string;
  contentType: string;
  schemaId: string;
  sample: string | null;
  examples: {
    default: {
      value: string;
    };
  };
};

export type PathParameter = {
  name: string;
  description: string;
  parameterContractType: ParameterContractType;
  isRequired: boolean;
  values?: string[];
};
export type QueryParameter = Omit<PathParameter, 'isRequired'> & {
  values: unknown[];
  schemaId: string;
  typeName: string;
  examples: unknown;
};
export type ConsoleOperation = {
  operationName: string;
  name: string;
  method: string;
  requestUrl: string;
  request: ConsoleRequest;
  urlTemplate: string;
  canHaveBody: boolean;
};

export type ConsoleRequest = {
  headers: ConsoleHeader[];
  meaningfulHeaders: ConsoleHeader[];
  body: string;
  bodyFormat: RequestBodyType;
};

export type ConsoleHeader = {
  name: string;
  value: string;
  secret: boolean;
  hiddenValue: string;
};

export enum RequestBodyType {
  raw = 'raw',
  string = 'string',
  binary = 'binary',
  form = 'form',
}

export type UserStatus = {
  isAuthenticated: boolean;
  tryRelogin: boolean;
  appVersion: string;
  notificationCount: number;
};

type NotificationType =
  | 'CommentReply'
  | 'CommentModeration'
  | 'CommentDocumentOwnerRequest'
  | 'News'
  | 'PublishRequest'
  | 'SuggestionComment'
  | 'SuggestionStatusChanged'
  | 'SuggestionEdited'
  | 'AddedToOrg'
  | 'RemovedFromOrg'
  | 'ApprovedToOrg'
  | 'DeniedFromOrg';
export type Notification = {
  id: number;
  type: NotificationType;
  pointer: string;
  title?: localeValue;
  time: Date | string;
  read: boolean;
};

export type SubscriptionCount = {
  apiId: string;
  count: number;
};

export type ApiMAnalyticsDTO = {
  subscriptionCounts: SubscriptionCount[];
  apiMReportByOperation: ApiMReportDTO[];
  apiMReportByTimestamp: ApiMReportDTO[];
};

export type ApiMReportDTO = {
  apiId: string;
  apiOperation: string;
  callCountSuccess: number;
  callCountTotal: number;
  timestamp: Date;
};

export type ApplicationInsightsCount = {
  timestamp: string;
  count: number;
};

export type ApplicationInsightsDTO = {
  maxUserCount: ApplicationInsightsCount[];
  pageViewCount: ApplicationInsightsCount[];
  loginCount: ApplicationInsightsCount[];
  newsViewCount: ApplicationInsightsCount[];
  commentViewCount: ApplicationInsightsCount[];
  blockViewCount: {
    blockId: number;
    blockName: localeValue;
    count7d: number;
    count30d: number;
  }[];
};

export type ContentAnalyticsDTO = {
  commentsAnalytics: CommentsAnalytics;
  userAnalytics: UserAnalytics;
  blockAnalytics: BlockAnalytics;
};

export type CommentsAnalytics = {
  commentsCounts: {
    [key: string]: number;
  };
};

export type UserAnalytics = {
  userCount: number;
  roleCounts: {
    [key: string]: number;
  };
  newsNotificationCount: number;
  commentsNotificationCount: number;
};

export type BlockAnalytics = {
  favoriteCounts: {
    [key: string]: number;
  };
  categoryCounts: {
    [key: string]: number;
  };
  publishedBlocksCount: number;
};

export type OrganizationUser = {
  userId: string;
  name: string;
  email: string;
};

export type OrganizationInfo = {
  organizationId: Guid;
  name: string;
  webpage: string;
  logo: ReturnType<typeof getBlobUrl>;
  country: string;
  numberOfEmployees: string;
};

export type OrganizationBlock = {
  blockId: Guid;
  name: localeValue;
  description: localeValue;
  category: Category;
  currentVersionId: number;
};

export type OrganizationData = OrganizationInfo & {
  isInternal: boolean;
  users: OrganizationUser[];
  requestedUsers: OrganizationUser[];
  blocks: OrganizationBlock[];
  frontendData?: string;
};

export const schemaTypeValues = [
  'singleline',
  'multiline',
  'markdown',
  'link',
  'image',
  // 'taggroup',
  'steps',
  'apim',
  'interactive_image',
  'layout',
] as const;
export type SchemaType = (typeof schemaTypeValues)[number];
export const stringSchemaTypeValues = [
  'singleline',
  'multiline',
  'markdown',
] satisfies Readonly<SchemaType[]>;
export const complexSchemaTypeValues = [
  'interactive_image',
  'layout',
] satisfies Readonly<SchemaType[]>;
export type StringSchemaType = (typeof stringSchemaTypeValues)[number];
export type ComplexSchemaType = (typeof complexSchemaTypeValues)[number];
export type ObjectSchemaType = Exclude<SchemaType, StringSchemaType>;

export type Schema = {
  type: SchemaType;
  name: string;
  title: string;
  showTitle: boolean;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  minlength?: number;
  maxlength?: number;
  /** Only used in CategoryEditor, not actually persisted to DB */
  requiredByTemplate?: boolean;
  canUnsetRequired?: boolean;
};

type validationMessages = 'required' | 'minLength' | 'maxLength';
export type validationInfo = {
  key: validationMessages;
  args: { [key: string]: string | number };
};

export type Link = {
  url: string;
  text: BlockLocaleValue;
  alt: BlockLocaleValue;
};

export type BlockImage = {
  /** Internal name used for storing the file in blobstorage */
  name: string;
  /** Text displayed to the user */
  alt: BlockLocaleValue;
};

export type BlockApimDetails = {
  apimId: string | undefined;
  useAuthentication?: boolean;
};
export function getApimDetails(data?: BlockData): BlockApimDetails | undefined {
  return getApimDetailsWithKey(data)?.value;
}
export function getApimDetailsWithKey(data?: BlockData):
  | {
      key: string;
      value: BlockApimDetails;
    }
  | undefined {
  const match = Object.entries(data ?? {}).find(
    ([, i]) => typeof i === 'object' && (i as BlockApimDetails).apimId,
  );
  return match
    ? { key: match[0], value: match[1] as BlockApimDetails }
    : undefined;
}

export function isBlockImage(value: BlockValue): value is BlockImage {
  return typeof value === 'object' && 'name' in value && 'alt' in value;
}

export type Point = { x: number; y: number };
export type Dimensions = { height: number; width: number };
export type InteractiveImageItem = {
  label: localeValue;
  extendedText: localeValue;
  image: BlockImage;
  size: Dimensions;
  position: Point;
  textOnly?: boolean;
  showLabelInVisual?: boolean;
  hideImageInVisual?: boolean;
  imageMaxWidthInText?: number;
  level: number;
};
export type InteractiveImage = BlockValueWithChildren<InteractiveImageItem> & {
  background: BlockImage;
  initialText: localeValue;
};

export type TagListVueAttributes = {
  list: TagGroup['id'] | 'categories';
  maxItems?: number;
  showAllTags? : boolean;
  showGroupLabel?: boolean;
};

export type LayoutItem = {
  id: Guid;
  markdown?: localeValue;
  doclist?: DoclistVueAttributes;
  searchbar?: SearchbarVueAttributes;
  image?: BlockImage;
  taglist?: TagListVueAttributes;
  children?: LayoutItem[];
  doclistFilterFor?: Guid;
  doclistSearchFor?: Guid;
} & LayoutItemOptions;
export type LayoutContentProperties = (typeof layoutContentProperties)[number] &
  keyof LayoutItem;
export const layoutContentProperties = [
  'markdown',
  'image',
  'doclist',
  'searchbar',
  'taglist',
  'children',
] as const satisfies (keyof LayoutItem)[];
export type TailwindSplitOptionPrefixes =
  | 'gap'
  | 'gap-x'
  | 'gap-y'
  | 'p'
  | 'px'
  | 'py'
  | 'w'
  | 'max-w'
  | 'min-w'
  | 'h'
  | 'min-h'
  | 'max-h'
  | 'top'
  | 'left';
export type TailwindSplitOption = {
  [key in TailwindSplitOptionPrefixes]?: string;
};
export type LayoutItemOptions = LayoutItemLayoutOptions & {
  contentType: LayoutContentProperties;
  bgColor?: Colors;
  url?: string;
  gap?: TailwindSplitOption;
  padding?: TailwindSplitOption;
  width?: TailwindSplitOption;
  height?: TailwindSplitOption;
  position?: TailwindSplitOption;
};
export const boxStyles = ['gnistLink', 'daisyUIwarning'] as const; // Each style must be manually added to getLinkLevelClasses()
type BoxStyle = (typeof boxStyles)[number];
export type LayoutItemLayoutOptions = {
  mode: 'block' | 'flex' | 'grid';
  cols?: number;
  colSpan?: number;
  mdCols?: number;
  boxStyle?: BoxStyle;
  vAlign?: 'items-start' | 'items-center' | 'items-end';
  hAlign?:
    | 'justify-items-start text-left'
    | 'justify-items-center text-center'
    | 'justify-items-end text-right';
  direction?: 'flex-row' | 'flex-row-reverse' | 'flex-col' | 'flex-col-reverse';
  wrap?: 'flex-wrap' | 'flex-nowrap';
  alignContent?:
    | 'content-normal'
    | 'content-center'
    | 'content-start'
    | 'content-end'
    | 'content-between'
    | 'content-around'
    | 'content-evenly'
    | 'content-stretch';
  justifyContent?:
    | 'justify-normal'
    | 'justify-start'
    | 'justify-end'
    | 'justify-center'
    | 'justify-between'
    | 'justify-around'
    | 'justify-evenly'
    | 'justify-stretch';
  alignItems?:
    | 'items-start'
    | 'items-end'
    | 'items-center'
    | 'items-baseline'
    | 'items-stretch';
  justifyItems?:
    | 'justify-items-start'
    | 'justify-items-end'
    | 'justify-items-center'
    | 'justify-items-stretch';
};

// Add other types as they are created
export type ComplexBlockValueTypes =
  | InteractiveImage
  | InteractiveImageItem
  | LayoutItem;

/** Allows for legacy values with no locale value */
export type BlockLocaleValue = localeValue | string;
export type BlockValue =
  | localeValue
  | Link
  | BlockImage
  | Step[]
  | BlockApimDetails
  | InteractiveImage
  | LayoutItem;
export type BlockValueLegacy = BlockLocaleValue | BlockValue;

export type BlockData = {
  [key: string]: BlockValue;
};

type BlockValueWithChildren<T> = { items: T[] };
type BlockValueChildItem = Extract<
  BlockValue,
  { items: unknown }
>['items'][number];
type NoItems<T> = T extends Extract<BlockValue, { items: unknown }> ? never : T;

/** Types of BlockValues that are NOT arrays AND the item type of BlockValues that are arrays (the type itself has no array types) */
export type BlockValueItemOnly =
  | NoItems<NotArray<BlockValue>>
  | ArrayItem<BlockValue>
  | BlockValueChildItem;
/** Only types of BlockValues that are objects (if they are arrays, this includes the item type, but not the array type) */
export type ObjectBlockValue = Exclude<BlockValueItemOnly, BlockLocaleValue>;
/** Blockvalues PLUS the single (item) type of those blockvalues that are arrays */
export type BlockValueAndArrayItem = BlockValue | BlockValueItemOnly;
export type BlockValueLegacyAndArrayItem =
  | BlockValueLegacy
  | BlockValueAndArrayItem;
export type BlockValueExceptLegacy<T extends BlockValueLegacyAndArrayItem> =
  T extends string ? localeValue : T;
// Returns a type based on a BlockValue with all localeValues changed to strings
type TranslatedBase<T> = T extends string
  ? string
  : T extends localeValue
    ? string
    : {
        [key in keyof OnlyKeysWithValsOfTypeButNotType<
          T,
          BlockLocaleValue,
          string
        >]: string;
      } & ExcludeKeysWithValsOfTypeButNotType<T, BlockLocaleValue, string>;
/** Translated version of a BlockValues. Note that this always returns a single item, so Translated<X[]> will return Translated<X>. */
export type Translated<T> =
  T extends Array<infer U>
    ? Translated<U>
    : T extends ComplexBlockValueTypes
      ? TranslatedComplexBlockValue<T>
      : TranslatedBase<T>;
export type TranslatedComplexBlockValue<
  TInObject extends ComplexBlockValueTypes,
> = {
  [TKey in keyof TInObject]: TInObject[TKey] extends
    | ObjectBlockValue
    | undefined
    ? Translated<TInObject[TKey]>
    : NonNullable<TInObject[TKey]> extends ComplexBlockValueTypes[]
      ? TranslatedComplexBlockValue<ArrayItem<NonNullable<TInObject[TKey]>>>[]
      : TInObject[TKey] extends BlockValue | undefined
        ? Translated<TInObject[TKey]>
        : TInObject[TKey];
};

export const getEmptyLocaleValue = (): localeValue => {
  return { [DEFAULT_LOCALE]: '' };
};
/** Used to check if property X in a BlockValue should be a localeValue */
export const emptyBlockValues = {
  image: {
    name: '',
    alt: getEmptyLocaleValue(),
  } as BlockImage,
  link: {
    url: '',
    text: getEmptyLocaleValue(),
    alt: getEmptyLocaleValue(),
  } as Link,
  // steps: [],
  // TODO: Taggroup default value
  steps: {
    order: 0,
    title: getEmptyLocaleValue(),
    description: getEmptyLocaleValue(),
    showApimOrderButton: false,
  } as Step,
  apim: {
    apimId: undefined,
  },
  interactive_image: {
    image: { name: '', alt: getEmptyLocaleValue() },
    label: getEmptyLocaleValue(),
    extendedText: getEmptyLocaleValue(),
    size: { height: 100, width: 250 },
    position: { x: 0, y: 0 },
    level: 1,
  } as InteractiveImageItem,
  layout: {
    markdown: getEmptyLocaleValue(),
    mode: 'block',
  } as LayoutItem,
} satisfies {
  [key in ObjectSchemaType]: BlockValueItemOnly & object;
};

export type BlockValueWithLocaleDate<T extends BlockValueItemOnly> = T & {
  localeUpdates: genericLocaleValue<Date>;
};
export function isBlockValueWithLocaleDate<T extends BlockValueItemOnly>(
  value: unknown,
): value is BlockValueWithLocaleDate<T> {
  return (
    !!value &&
    typeof value === 'object' &&
    (value as BlockValueWithLocaleDate<T>).localeUpdates !== undefined
  );
}

export type ExposeOnSave<T extends BlockValue> = {
  /** Will be called after an item is saved to the database. */
  onSaved?(): void;
  /** Gives the exposer an option to:
   * 1. manipulate the original object, and
   * 2. return a copy of the original object that should be submitted to the database.\
   * _Return undefined if you want to save the existing data object to the database._ */
  onBeforeSave?(data: BlockData, name: string): T | undefined;
};

export const routeTypes = ['page', 'hardcoded', 'header'] as const;
export type routeType = (typeof routeTypes)[number];
export type headerType = 'menu' | 'menuOnly' | 'routeOnly';
export type RouteCustomOptions = {
  fullscreen?: boolean;
  hideShimmer?: boolean;
  fgColor?: FgColors;
};
type RouteItemBase = {
  text: string;
  /** Used if pointing to something not controlled by vue router */
  href?: string;
  onClick?: () => void;
  targetPath: string;
  meta?: Pick<
    RouteMeta,
    'translationid' | 'targetDoc' | 'fullScreen' | 'customOptions'
  >;
  /** Used by blocks to define the routename for the listing route, and by 'hardcoded' to define the matcher to match on the hardcoded route */
  routeBaseName?: string;
  mobileOnly?: boolean;
  customOptions?: RouteCustomOptions;
  action?: Action;
  subject?: Subject;
  query?: LocationQuery;
  headerType?: headerType;
  targetMenu?: 'main'; // Current use: if item should not be in menu, possible future use: if we add different menus in differnt places
  routeType: routeType;
  aliases?: string[];
  isSelected?: boolean;
  isDocsListRoute?: boolean;
};
export type RouteItemWithChildren = Omit<RouteItemBase, 'click' | 'query'> & {
  items: RouteItem[];
};
export type RouteItem = RouteItemBase | RouteItemWithChildren;

export type genericLocaleValue<T> = { [DEFAULT_LOCALE]: T } & {
  [key in supported_locale]?: T;
};
export type localeValue = genericLocaleValue<string>;
export type RouteItemDTO = Pick<
  RouteItem,
  'mobileOnly' | 'headerType' | 'targetMenu' | 'routeType' | 'isDocsListRoute'
> &
  Pick<RouteMeta, 'targetDoc' | 'customOptions'> & {
    id: number;
    parent?: string;
    routeName: string;
    aliases?: string[];
    text: localeValue;
    sortOrder?: number;
  };

export const error_pages = {
  401: 'error_401',
  403: 'error_403',
  404: 'error_404',
} as const;

export type AlzFormTopic = {
  key: string;
  label: localeValue;
  recipient: string;
};
export type AlzFormData = {
  topic: AlzFormTopic;
  senderEmail: string;
  senderName?: string;
  subject: string;
  request: string;
};
type EmailRecipient = { name?: string; email: string };
export type ContactFormDTO = {
  recipients: EmailRecipient[];
  subject: string;
  sender: EmailRecipient;
  mainBody: string;
};

export type ApplicationService = {
  name?: string;
  applicationUUID: string;
};
export type AgreementReference = {
  agreementNumber: string;
  forUserId?: string;
  forUserFullname?: string;
  costCenter?: string;
  label?: string;
  /** Used to differentiate from data coming when reading lz details, which only has the actual agreementNumber and not the rest of the columns from DB */
  readFromDb?: boolean;
};
export type Company = {
  name?: string;
  shortName: string;
};
export type ArchType = SimpleLookupValue & {
  roles?: [];
  targetRepo?: string;
};
export type InformationClass = SimpleLookupValue & {
  /** If not true,  this information class may not be used by sandboxes */
  sandbox?: boolean;
};
export type SimpleLookupValue<T = string> = {
  key: T;
  text: localeValue;
  extendedDescription: localeValue;
};
export type FieldLabel = {
  group: FieldSetKey;
  field: string;
  label: localeValue;
  tooltip?: localeValue;
  placeholder?: localeValue;
  description?: localeValue;
  link?: string;
  richTextDescription?: boolean;
};
export type CustomText = {
  group: TextSetKey;
  identifier: string;
  text: localeValue;
};
export const formConfigOwners = ['AzureLandingZones'] as const;
export type FormConfigOwner = (typeof formConfigOwners)[number];
export type FormConfigDTO = { owner: ''; jsonValue: string };
export type FormConfig = (FormConfigDTO & { config: unknown }) | AlzFormConfig; // Add other classes as needed
export type AlzFormConfig = {
  owner: 'AzureLandingZones';
  config: AlzStaticConfig;
};
export type AlzStaticConfig = {
  location: string;
  workload: string;
  sandboxApplicationUUID: string;
  pr_template: string;
};

export const alzOperationTypes = [
  'team_ops',
  'cpo_ops',
  'delegated_ops',
] as const;
export type AlzOperationType = (typeof alzOperationTypes)[number];
export const alzEnvironmentTypes = [
  'Prod',
  'PreProd',
  'Test',
  'Development',
  'Troubleshooting',
] as const;
export const alzSandboxEnvironmentTypes = ['Trial', 'Course'] as const;
export type AlzEnvironmentType =
  | (typeof alzEnvironmentTypes)[number]
  | (typeof alzSandboxEnvironmentTypes)[number];
export const testDataTypes = [
  'synthetic',
  'anonymized',
  'indirect_identifiable',
  'person_sensitive',
] as const;
export type TestDataType = (typeof testDataTypes)[number];

export type AlzEnvironment = {
  alias?: string;
  fileIdentifier: string;
  existingFileIdentifier?: string;
  name: string;
  type?: AlzEnvironmentType;
  test_data?: TestDataType;
  sandbox?: boolean;
  ipam_network_cidr?: string;
  sub_CI_UUID?: string;
  orderRoles?: string[];
  sandbox_repo_name?: string;
};
export type Repo = {
  name: string;
  landing_zones: string[];
};
export type AlzSystem = Pick<
  ExtendedAlzSystemDefinitionDTO,
  | 'pullRequestId'
  | 'pullRequestUrl'
  | 'systemFileUrl'
  | 'endDate'
  | 'ownerTag'
  | 'environments'
> & {
  name: string;
  systemId?: string;
  owner?: { objectId: string; displayName: string };
  workloadName: string;
  service?: ApplicationService;
  company?: Company;
  agreementNumber?: AgreementReference;
  crit?: SimpleLookupValue<number>;
  infoClass?: SimpleLookupValue;
  archType?: ArchType;
  ops?: AlzOperationType;
  opsCi?: string;
  repos: Repo[];
  isSandboxSystem: boolean;
  customProducts: CustomProduct[];
};
export const batRoleTypes = ['lz_role', 'gh_role'];
type BatRoleType = (typeof batRoleTypes)[number];
export type CustomProduct = {
  title: string;
  roles: { type: BatRoleType; roleId: string; objectId: string }[];
};

export type ExtendedAlzSystemDefinitionDTO = AlzSystemDefinitionDTO & {
  pullRequestId?: number;
  pullRequestUrl?: string;
  systemFileUrl?: string;
};

export type AlzSystemDefinitionDTO = {
  ownerObjectId?: string;
  agreementNumber?: string;
  costCenter?: string;
  crit?: number;
  infoClass?: string;
  archType?: string;
  vendingRepo?: string;
  ownerTag?: string;
  workloadName: string;
  environments: AlzEnvironment[];
  endDate?: Date;
  systemFileData: AlzSystemDefinitionJsonFile;
  productsData: {
    customProducts: CustomProduct[];
    orderRoles?: { [environment: string]: string[] };
  };
};
type AlzSystemDefinitionJsonFile = {
  id?: string;
  name: string;
  application_uuid?: string;
  hf?: string;
  operations_model?: AlzOperationType;
  operations_ci_uuid?: string;
  sandbox: boolean;
  repositories?: Repo[];
};
export type AlzSystemDefinitionItem = {
  pullRequestId?: number;
  source?: 'MainBranch' | 'PullRequest';
  data: AlzSystemDefinitionJsonFile;
};
