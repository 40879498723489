<script setup lang="ts">
import { getBlobUrl, type IBlobUrlInput } from '@/api/blob';
import type { BlockImage, Schema, Translated, VueClass } from '@/api/types';
import { computed, ref, useAttrs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { RouteLocationRaw } from 'vue-router';
import OptionalDiv from '../OptionalDiv.vue';

const props = defineProps<{
  schema?: Schema;
  value: Translated<BlockImage> | { fullUrl: string; alt?: string };
  blobLocation?: IBlobUrlInput;
  decorative?: boolean;
  longDescTarget?: RouteLocationRaw;
  linkToLongDesc?: boolean;
  noDrag?: boolean;
  noDefaultClass?: boolean;
  captionClass?: VueClass;
  showCaption?: boolean;
}>();
const imgRef = ref<HTMLImageElement | undefined>();
const loaded = ref(false);
const size = ref<{
  width: number;
  height: number;
  naturalWidth: number;
}>({ width: 0, height: 0, naturalWidth: 0 });
function refreshSize() {
  size.value = {
    width: imgRef.value?.width ?? 0,
    height: imgRef.value?.height ?? 0,
    naturalWidth: imgRef.value?.naturalWidth ?? imgRef.value?.width ?? 0,
  };
}
watch([loaded, imgRef], () => {
  if (!loaded.value || !imgRef.value) return;
  refreshSize();
});
defineExpose({ size, refreshSize });

const { t } = useI18n();

const imageUrl = computed(() => {
  if ('fullUrl' in props.value) return props.value.fullUrl;
  if (!props.value?.name || !props.blobLocation) return undefined;
  return getBlobUrl(props.blobLocation, props.value.name);
});
defineOptions({
  inheritAttrs: false,
});
const attrs = useAttrs();
</script>

<template>
  <OptionalDiv v-if="value" :show="schema?.showTitle">
    <h2
      v-if="schema?.showTitle"
      :id="schema.title.replace(/[^a-z1-9-_]/gi, '-')"
    >
      {{ schema.title }}
    </h2>
    <div v-bind="attrs" class="flex w-full items-start">
      <img
        ref="imgRef"
        :src="imageUrl"
        :alt="decorative ? '' : value.alt"
        :class="noDefaultClass ? '' : 'max-h-96 max-w-full'"
        :role="decorative ? 'presentation' : undefined"
        :longdesc="longDescTarget"
        :draggable="noDrag === true ? false : undefined"
        @load="loaded = true"
      />
      <RouterLink
        v-if="longDescTarget && linkToLongDesc"
        class="not-sr-only focus:not-sr-only"
        :to="longDescTarget"
        @click.prevent
      >
        {{ t('accessibility.longdescLinkText') }}
      </RouterLink>
    </div>
    <div
      v-if="showCaption && value.alt"
      class="text-left italic"
      :class="captionClass"
    >
      {{ value.alt }}
    </div>
  </OptionalDiv>
</template>
