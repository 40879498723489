<script setup lang="ts">
import { useCategoriesLazy } from '@/api/category';
import { createBlock, updateBlock } from '@/api/blocks';
import {
  type Block,
  type BlockApimDetails,
  type BlockCreateDTO,
  type Category,
  type UpdateBlock,
  type BlockData,
  getApimDetailsWithKey,
  type Version,
} from '@/api/types';
import { computed, ref, toRef, watch, watchEffect } from 'vue';
import { useRouter, type RouteLocationRaw, useRoute } from 'vue-router';
import ButtonComponent from '../ButtonComponent.vue';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import ApimEditor from './ApimEditor.vue';
import ModalComponent from '../ModalComponent.vue';
import { useI18n } from 'vue-i18n';
import { useOrganization, useOrganizations } from '@/api/organization';
import TagDropdown from '../forms/InputTagDropdown.vue';
import { arrayEquals } from '@/utilities/arrayEquals';
import { useCurrentUser } from '@/api/auth';
import { useStoreAbility } from '@/abilities';
import { useRefreshable } from '@/utilities/useRefreshable';
import { deepClone } from '@/utilities/deepClone';
import { editBlockVersionsRouteId, editBlocksRouteId } from '@/config';
import { translateStringOrLocale } from '@/i18n';
import { localeValueEqual } from '@/utilities/localeValueEqual';
import SchemaEditor from '@/components/SchemaEditor.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import { pageTemplates } from '@/renderTemplates';
import { mapValueList } from '@/utilities/mapValueList';
import { getRouteTitle } from '@/utilities/routeUtils';
import { getVersion } from '@/api/versions';
import { useRoles } from '@/api/user';

const { t } = useI18n();
const { can } = useStoreAbility();

const props = defineProps<{
  source: Block;
  isNewBlock: boolean;
  cancelRoute: RouteLocationRaw;
}>();

type EditableBlock = Block;

const block = ref<EditableBlock>(deepClone(props.source));
const router = useRouter();
const route = useRoute();
const form = ref<HTMLFormElement | null>();
const showModal = ref<boolean>(false);
const { result: categories } = useCategoriesLazy();
const { result: owners } = useOrganizations(can('read', 'Organization'));
watch(owners, () => {
  if (!owners.value || block.value.ownerOrg) return;
  if (owners.value.length === 1) block.value.ownerOrg = owners.value[0];
  if (route.query.ownerOrg) {
    const ownerOrg = owners.value.find(
      (o) => o.organizationId === route.query.ownerOrg,
    );
    if (ownerOrg) block.value.ownerOrg = ownerOrg;
  }
});
const { result: ownerDetails } = useOrganization(
  toRef(() => block.value.ownerOrg?.organizationId),
);
watch(ownerDetails, () => {
  if (!ownerDetails.value || (block.value.documentOwners ?? []).length > 0) {
    return;
  }
  const loggedOnUserOwner = ownerDetails.value.users.find(
    (u) => u.userId === user.value?.id,
  );
  if (loggedOnUserOwner) block.value.documentOwners = [loggedOnUserOwner];
  else if (ownerDetails.value.users.length === 1) {
    block.value.documentOwners = [ownerDetails.value.users[0]];
  }
});
watch(
  () => block.value?.ownerOrg?.organizationId,
  (newVal, oldVal) => {
    if (newVal !== oldVal) block.value.documentOwners = [];
  },
);

const apimFieldInSchema = computed(
  () => block.value?.category?.schema?.find((i) => i.type === 'apim')?.name,
);
const apimDataMovedToAnotherField = ref(false);
const sourceContent = ref<BlockData>();
const updatedContent = ref<BlockData>();
const [apimValue, refreshApimValue] = useRefreshable({
  get() {
    if (!updatedContent.value) return undefined;
    if (!apimFieldInSchema.value) return undefined;
    const { key: fieldUsedInContent, value } =
      getApimDetailsWithKey(updatedContent.value) ?? {};
    if (fieldUsedInContent && fieldUsedInContent !== apimFieldInSchema.value) {
      // Move to the other key and set a flag that we moved it
      updatedContent.value[apimFieldInSchema.value] =
        updatedContent.value[fieldUsedInContent];
      delete updatedContent.value[fieldUsedInContent];
      apimDataMovedToAnotherField.value = true;
    }
    return value;
  },
  set(newValue) {
    if (!apimFieldInSchema.value || !block.value) return; // Should not happen
    if (!updatedContent.value) return;
    if (newValue) {
      updatedContent.value[apimFieldInSchema.value] = newValue;
    } else {
      if (apimFieldInSchema.value in updatedContent.value) {
        delete updatedContent.value[apimFieldInSchema.value];
      }
    }
  },
});
watch(
  block,
  async () => {
    if (!block.value) return;
    const version: Pick<Version, 'content'> | null = props.isNewBlock
      ? { content: {} }
      : await getVersion(block.value.blockId, block.value.currentVersionId);
    sourceContent.value = version?.content;
    updatedContent.value = deepClone(version?.content ?? {});
  },
  { immediate: true },
);
watch(sourceContent, () => refreshApimValue(), { deep: true });
const apimValueHasChanged = computed(() => {
  if (!apimFieldInSchema.value) return false;
  if (apimDataMovedToAnotherField.value) return true;
  const oldValue = sourceContent.value?.[
    apimFieldInSchema.value
  ] as BlockApimDetails;
  if (!oldValue && apimValue.value) return true;
  if (oldValue && !apimValue.value) return true;
  return (
    apimValue.value?.apimId !== oldValue?.apimId ||
    apimValue.value?.useAuthentication != oldValue?.useAuthentication
  );
});

async function submit(): Promise<void> {
  if (!form.value || !form.value.checkValidity()) return;
  if (props.isNewBlock) await createNewBlock();
  else await updateExistingBlock();
}

async function createNewBlock(): Promise<void> {
  if (block.value.category) {
    const blockDTO: BlockCreateDTO = {
      name: block.value.name,
      description: block.value.description,
      categoryId: block.value.category.categoryId,
      customSchema: block.value.customSchema,
      tags: block.value.tags.map((i) => i.id),
      documentOwnerIds: block.value.documentOwners?.map((u) => u.userId),
      ownerOrganizationId: block.value.ownerOrg?.organizationId,
      audiences: block.value.audiences,
      content: apimValueHasChanged.value ? updatedContent.value : undefined,
    };
    const { blockId, currentVersionId } = await createBlock(blockDTO);
    router.push({
      name: 'edit_version',
      params: { blockId, versionId: currentVersionId },
    });
  }
}

async function updateExistingBlock(): Promise<void> {
  if (block.value.category) {
    const blockDTO: UpdateBlock = {
      blockId: block.value.blockId,
      name: block.value.name,
      description: block.value.description,
      categoryId: block.value.category.categoryId,
      customSchema: block.value.customSchema,
      tags: block.value.tags.map((i) => i.id),
      OwnerOrganizationId: block.value.ownerOrg?.organizationId,
      documentOwnerIds: block.value.documentOwners?.map((u) => u.userId),
      audiences: block.value.audiences,
      content: apimValueHasChanged.value ? updatedContent.value : undefined,
    };
    await updateBlock(blockDTO);
    router.push({
      name: editBlockVersionsRouteId,
      params: { blockId: block.value.blockId },
    });
  }
}

const blockHasChanged = computed(() => {
  return (
    !localeValueEqual(block.value.description, props.source.description) ||
    block.value.category?.categoryId !== props.source.category?.categoryId ||
    !arrayEquals(block.value.tags, props.source.tags, (tag) => tag.id) ||
    !arrayEquals(block.value?.audiences, props.source.audiences) ||
    !arrayEquals(
      block.value.documentOwners,
      props.source.documentOwners,
      (user) => user.userId,
    ) ||
    block.value.ownerOrg?.organizationId !==
      props.source.ownerOrg?.organizationId ||
    !localeValueEqual(block.value.name, props.source.name) ||
    apimValueHasChanged.value ||
    block.value.customSchema?.template !==
      props.source.customSchema?.template ||
    JSON.stringify(block.value.customSchema?.schema) !==
      JSON.stringify(props.source.customSchema?.schema)
  );
});

const canSave = computed(() => {
  if (!block.value) return false;
  if (!props.isNewBlock && !blockHasChanged.value) return false;
  return (
    block.value.name.no.length > 1 &&
    (block.value.name.en?.length ?? 0) > 1 &&
    !!block.value.ownerOrg?.organizationId &&
    (block.value.documentOwners?.length ?? 0) > 0 &&
    !!block.value.category &&
    (!apimValue.value || !!apimValue.value.apimId)
  );
});

const doNotSaveBackLocation = computed(() => {
  return props.isNewBlock
    ? { name: editBlocksRouteId }
    : {
        name: editBlockVersionsRouteId,
        params: { blockId: props.source.blockId },
      };
});

const ownerOrgOptions = computed(() =>
  toDropdownItems(owners.value, (o) => [o.organizationId, o.name, o]),
);
const ownerUserOptions = computed(() => {
  return toDropdownItems(ownerDetails.value?.users, (u) => [
    u.userId,
    u.name,
    u,
  ]);
});
const user = useCurrentUser();
const filterCategories = (categories: Category[] | null): Category[] | null => {
  if (categories === null || user.value === null) return null;
  const roles = user.value.roles ?? [];
  return categories.filter(
    (category) =>
      !category.createRoles ||
      category.createRoles.length === 0 ||
      category.createRoles?.some((role) => roles.includes(role)),
  );
};
const categoryOptions = computed(() =>
  toDropdownItems(filterCategories(categories.value), (c) => [
    c.categoryId.toString(),
    translateStringOrLocale(c.name).value ?? c.name.no,
    c,
  ]),
);

const isCustomPage = computed(() => !!block.value.customSchema);
const canCreateCustomPage = can('create', 'CustomBlock');
const pageTemplateOptions = mapValueList(
  pageTemplates.map((t) => t.id),
  'templates',
  true,
);
const blockTitle = translateStringOrLocale(() => block.value?.name);
watchEffect(() => {
  if (blockTitle.value)
    document.title = getRouteTitle([
      t('admin.blockProduction.detailsTitle'),
      blockTitle.value,
    ]);
});

const { result: roles } = useRoles(['AudienceTargeting']);
const roleOptions = computed(() =>
  mapValueList(roles.value?.map((r) => r.name) ?? [], 'roleSelection'),
);
</script>
<template>
  <form
    ref="form"
    class="flex grid-cols-2 flex-col items-stretch gap-x-4 md:grid"
    @submit.prevent="submit"
  >
    <Checkbox
      v-if="canCreateCustomPage"
      :model-value="isCustomPage"
      label="admin.blockProduction.isCustomPage"
      direction="horizontal"
      mode="toggle"
      class="self-center"
      @update:model-value="
        (checked) => {
          block.customSchema = checked
            ? { schema: [], template: 'fullPage' }
            : undefined;
        }
      "
    />
    <div v-if="canCreateCustomPage">
      <Dropdown
        v-if="block.customSchema"
        v-model="block.customSchema.template"
        label="categoryAdmin.template.page"
        :options="pageTemplateOptions"
        :getkey="(val) => val ?? 'empty'"
      />
    </div>

    <TextInput
      v-model="block.name.no"
      label="admin.blockProduction.nameNo"
      required
      :minlength="1"
      :maxlength="80"
      tooltip="admin.blockProduction.nameTooltip"
      placeholder="admin.blockProduction.namePlaceholder"
    />
    <TextInput
      v-model="block.name.en"
      label="admin.blockProduction.nameEn"
      required
      :minlength="1"
      :maxlength="80"
      tooltip="admin.blockProduction.nameTooltip"
      placeholder="admin.blockProduction.namePlaceholder"
    />
    <Dropdown
      v-model="block.ownerOrg"
      label="admin.blockProduction.ownerOrg"
      tooltip="admin.blockProduction.ownerOrgTooltip"
      :options="ownerOrgOptions"
      placeholder="admin.blockProduction.ownerOrgPlaceholder"
      :getkey="(o) => o?.organizationId"
      required
    />
    <Dropdown
      v-model="block.documentOwners"
      label="admin.blockProduction.documentOwner"
      tooltip="admin.blockProduction.documentOwnerTooltip"
      :options="ownerUserOptions"
      :multiple="{
        emptyLabel: 'admin.blockProduction.documentOwnerPlaceholder',
      }"
      :getkey="(o) => o?.userId"
      required
    />
    <div
      class="col-span-2 flex grid-cols-2 grid-rows-[repeat(2,1fr)] flex-col items-stretch gap-x-4 md:grid"
    >
      <Dropdown
        v-model="block.category"
        label="admin.blockProduction.category"
        tooltip="admin.blockProduction.categoryTooltip"
        :options="categoryOptions"
        placeholder="admin.blockProduction.categoryPlaceholder"
        :getkey="(c) => c?.categoryId"
        required
      />
      <TagDropdown
        v-model="block.tags"
        label="admin.blockProduction.tags"
        suggestedlabel="admin.blockProduction.suggestedTags"
        tooltip="admin.blockProduction.tagsTooltip"
        :multiple="{ emptyLabel: 'admin.blockProduction.tagsPlaceholder'}"
        :taglistclass="['max-h-24 overflow-y-auto']"
        :category="block.category"
      />
      <Dropdown
        v-model="block.audiences"
        label="admin.blockProduction.audience"
        tooltip="admin.blockProduction.audienceTooltip"
        :multiple="{ emptyLabel: 'admin.blockProduction.audiencePlaceholder' }"
        :options="roleOptions"
        :getkey="(c) => c"
      />
    </div>
    <ApimEditor
      v-if="apimFieldInSchema && !!updatedContent"
      v-model="apimValue"
      mode="Block"
      :is-new-block="isNewBlock"
      class="col-span-2"
      @read-metadata="
        ({ displayName, description }) => {
          if (displayName) {
            block.name.no = displayName;
            block.name.en = displayName;
          }
          if (description) {
            block.description.no = description;
            block.description.en = description;
          }
        }
      "
    />

    <TextInput
      v-model="block.description.no"
      label="admin.blockProduction.descriptionNo"
      multiline
      :minlength="1"
      :maxlength="240"
      :rows="7"
      tooltip="admin.blockProduction.descriptionTooltip"
      placeholder="admin.blockProduction.descriptionPlaceholder"
      class="col-span-2 w-full"
    />
    <TextInput
      v-model="block.description.en"
      label="admin.blockProduction.descriptionEn"
      multiline
      :minlength="1"
      :maxlength="240"
      :rows="7"
      tooltip="admin.blockProduction.descriptionTooltip"
      placeholder="admin.blockProduction.descriptionPlaceholder"
      class="col-span-2 w-full"
    />
    <SchemaEditor
      v-if="block.customSchema"
      v-model="block.customSchema.schema"
      legend="admin.blockProduction.customSchemaLegend"
      class="col-span-2"
    />
    <br />
    <div class="col-span-2 flex justify-between">
      <div class="mt-4">
        <ButtonComponent
          :text="t('admin.blockProduction.cancel')"
          @click="
            blockHasChanged ? (showModal = true) : router.push(cancelRoute)
          "
        />
        <ModalComponent
          :show-modal="showModal"
          :title="''"
          @close="showModal = false"
        >
          <template #default>
            <p>{{ t('admin.blockProduction.confirmExit') }}</p>
          </template>
          <template #buttons>
            <div class="gnist-button-group">
              <ButtonComponent
                :text="t('admin.blockProduction.save')"
                type="primary"
                :disabled="!canSave"
                @click="submit"
              />
              <RouterLink class="gnist-button" :to="doNotSaveBackLocation">
                {{ t('admin.blockProduction.doNotSave') }}
              </RouterLink>
              <ButtonComponent
                :text="t('admin.blockProduction.cancel')"
                @click="showModal = false"
              />
            </div>
          </template>
        </ModalComponent>
      </div>
      <ButtonComponent
        :text="
          isNewBlock
            ? t('admin.blockProduction.next')
            : t('admin.blockProduction.saveChanges')
        "
        class="mt-4"
        type="primary"
        :submit="true"
        :disabled="!canSave"
        data-cy-id="SaveDetailsButton"
      />
    </div>
  </form>
</template>
