<script setup lang="ts" generic="T extends Tag[] | undefined">
import { type IInputLabelProps } from './InputLabel.vue';
import Dropdown, {
  toGroupedDropdownItems,
  type IDropdownItem,
} from './InputDropdown.vue';
import type { Category, Tag } from '@/api/types';
import { useNestedMV } from '@/utilities/useInternalState';
import { useTags } from '@/api/tags';
import { ref, watchEffect } from 'vue';
import { translateStringOrLocale } from '@/i18n';
import { deepClone } from '@/utilities/deepClone';

const props = defineProps<
  IInputLabelProps & {
    modelValue: T;
    category?: Category;
    suggestedlabel?: string;
    tooltip?: string;
    label?: string;
    taglistclass?: string[];
    multiple?: { emptyLabel: string, maxItems?: number }
  }
>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: T): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();

const value = useNestedMV(props, (val) => emit('update:modelValue', val), true) as T;

const { result: tags } = useTags();

const tagOptions = ref<IDropdownItem<Tag>[]>([]);

watchEffect(() => {
  tagOptions.value = [];
  let list = deepClone(tags.value);
  if (props.category?.tagGroups?.length ?? 0 >= 1) {
    list?.forEach(
      (t) =>
        (t.tagGroups = t.tagGroups?.filter((tg) =>
          props.category?.tagGroups?.includes(tg.id.toString()),
        )),
    );
    list =
      list?.filter((t) =>
        t.tagGroups?.some((tg) =>
          props.category?.tagGroups?.includes(tg.id.toString()),
        ),
      ) ?? [];
  }

  tagOptions.value = toGroupedDropdownItems(list, (t) => [
    t.id.toString(),
    translateStringOrLocale(t.text).value,
    translateStringOrLocale(
      t.tagGroups?.find((tg) => tg.id === t.primaryTagGroupId)?.label ||
        t.tagGroups?.[0]?.label,
    ).value ?? '',
    t,
  ]);
});
</script>

<template>
  <Dropdown
    v-model="value"
    :label="label"
    :tooltip="tooltip"
    :suggestedlabel="suggestedlabel"
    :allow-empty-value="true"
    :multiple="multiple"
    :options="tagOptions"
    :getkey="(c) => c?.id"
  />
</template>
